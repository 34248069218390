import { useState, useRef, useEffect } from 'react'

function useBrandsFilters() {
	const [activeFilter, setActiveFilter] = useState()
	const [brandsContainerHeight, setBrandsContainerHeight] = useState()
	const brandsContainerRef = useRef(null)

	const handleFilterClick = (filter) => {
		setActiveFilter(filter)
	}

	useEffect(() => {
		if (brandsContainerRef.current) {
			setBrandsContainerHeight(brandsContainerRef.current.offsetHeight)
		}
	}, [brandsContainerRef.current])

	useEffect(() => {
		setActiveFilter('all')
	}, [])

	const brandFilterIsActive = (filterTags) =>
		activeFilter === 'all' || filterTags.includes(activeFilter)

	return {
		activeFilter,
		brandsContainerHeight,
		brandsContainerRef,
		handleFilterClick,
		brandFilterIsActive,
	}
}

export default useBrandsFilters
