import React from 'react'
import styled from 'styled-components'
import { breakpoint, getColor } from '../../shared/theme'

const FiltersWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 30px;
	height: fit-content;
`

const FilterButton = styled.button`
	border: none;
	font: inherit;
	cursor: pointer;
	outline: inherit;
	color: ${(props) =>
		props.active ? getColor('white') : getColor(props.textColour)};
	background-color: ${(props) =>
		props.active ? getColor('orange') : getColor(props.backgroundColour)};
	padding: 5px 18px;
	font-size: 14px;
	font-weight: 300;
	text-transform: capitalize;
	width: 100%;

	@media ${breakpoint('tablet')} {
		width: 30%;
	}
`

const BrandFilters = ({
	handleFilterClick,
	activeFilter,
	filterTags,
	backgroundColour,
}) => {
	const textColour = backgroundColour === '#000000' ? 'white' : 'black'

	return (
		<FiltersWrapper>
			<FilterButton
				onClick={() => handleFilterClick('all')}
				active={activeFilter === 'all'}
				backgroundColour={backgroundColour}
				textColour={textColour}
			>
				All
			</FilterButton>
			{filterTags &&
				filterTags.map((filter, index) => {
					return (
						<FilterButton
							key={index}
							onClick={() => handleFilterClick(filter.filterOption)}
							active={activeFilter === filter.filterOption}
							backgroundColour={backgroundColour}
							textColour={textColour}
						>
							{filter.filterOption}
						</FilterButton>
					)
				})}
		</FiltersWrapper>
	)
}

export default BrandFilters
