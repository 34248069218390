import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { breakpoint, getColor } from '../../shared/theme'
import Heading from '../../elements/typography/sectionHeading'
import ContentContainer from '../contentContainer'
import BackgroundImage from '../backgroundImage'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const SectionWrapper = styled.section`
	padding: 52px 0 32px;

	@media ${breakpoint('tablet')} {
		padding: 75px 0;
	}

	@media ${breakpoint('laptop')} {
		padding: 102px 0;
	}

	.slick-slider {
		.slick-dots {
			width: 40%;
			margin: 0 -5px;
			position: relative;
			bottom: auto;
			left: auto;
			line-height: 1;
			text-align: left;

			li {
				margin: 0 3px;
				width: auto;
				height: auto;

				button {
					height: 12px;
					width: 12px;
					padding: 0;

					&::before {
						height: 12px;
						width: 12px;
						color: #d9d9d9;
						font-size: 12px;
					}
				}

				&.slick-active {
					button {
						&::before {
							color: ${getColor('orange')};
						}
					}
				}
			}

			@media ${breakpoint('tablet')} {
				display: none;
			}
		}
	}
`

const SectionHeader = styled.div`
	margin-bottom: 29px;

	@media ${breakpoint('tablet')} {
		margin-bottom: 100px;
	}
`

const ProgressWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 14px;
	margin-bottom: 45px;
`

const ProgressItem = styled.div`
	width: 15.5%;
	cursor: pointer;
`

const ProgressTitle = styled.h4`
	font-size: 20.5px;
	line-height: 24.6px;
	font-weight: 300;
	margin-bottom: 14px;
`

const ProgressBarOuter = styled.div`
	width: 100%;
	height: 10px;
	background-color: ${getColor('black')};
	position: relative;
	margin-bottom: 32px;

	@media ${breakpoint('tablet')} {
		margin-bottom: 0;
	}
`

const ProgressBarInner = styled.span`
	background-color: ${getColor('orangeProgress')};
	height: 100%;
	width: 0%;
	display: block;
`

const Slide = styled.div`
	width: 100%;
	display: block;
`

const SlideInner = styled.div`
	width: 100%;
	height: 100%;
	display: block;

	@media ${breakpoint('tablet')} {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 0;
	}
`

const ImageWrapper = styled.div`
	width: 100%;
	aspect-ratio: 1.015;
	margin-bottom: 25px;
	position: relative;

	@media ${breakpoint('tablet')} {
		width: 33.6%;
		aspect-ratio: 1.013;
		margin-bottom: 0;
	}
`

const SlideCopy = styled.p`
	margin: 0;
	font-size: 14.8px;
	line-height: 17.8px;
	font-weight: 300;
	width: 85.8%;

	@media ${breakpoint('tablet')} {
		font-size: 25px;
		line-height: 30px;
		width: 61.4%;
	}
`

const SlideTitle = styled.p`
	margin: 0;
	position: absolute;
	bottom: 20px;
	left: 20px;
	color: rgb(210, 199, 186);
	font-weight: 800;
	text-transform: uppercase;
	font-size: 12px;

	@media ${breakpoint('laptop')} {
		font-size: 16px;
	}
`

const slickSettings = {
	infinite: true,
	autoplay: false,
	speed: 200,
	slidesToShow: 1,
	slidesToScroll: 1,
	initialSlide: 0,
	fade: true,
	nextArrow: null,
	prevArrow: null,
	responsive: [
		{
			breakpoint: 889,
			settings: {
				dots: true,
			},
		},
	],
}

export const SlyleticaCategories = ({ heading, categories }) => {
	if (!categories?.length) {
		return null
	}

	const [isMobileView, setIsMobileView] = useState()
	const [activeIndex, setActiveIndex] = useState(-1)
	const containerRef = useRef()
	const sliderRef = useRef()
	const progressBarRefs = useRef([])

	const onClickSliderNav = (index) => {
		setActiveIndex(index)
		sliderRef.current.slickGoTo(index)
	}

	useEffect(() => {
		if (activeIndex < 0) {
			setActiveIndex(0)
		}

		const handleResize = () => {
			setIsMobileView(window.innerWidth <= 539)
		}

		// Add event listener for window resize
		window.addEventListener('resize', handleResize)

		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	useEffect(() => {
		setIsMobileView(containerRef.current.offsetWidth <= 539)
	}, [containerRef])

	useEffect(() => {
		const progressBar = progressBarRefs.current[activeIndex]
		let progress = 0
		let animationFrameId

		const animate = (timestamp) => {
			if (!progressBar) return

			if (!progress) progress = timestamp

			const elapsed = timestamp - progress
			progress = timestamp

			const newWidth =
				parseFloat(progressBar.style.width || 0) + (elapsed / 8000) * 100 // 8000 milliseconds = 8 seconds

			if (newWidth <= 100) {
				progressBar.style.width = `${newWidth}%`
				animationFrameId = requestAnimationFrame(animate)
			} else {
				// Reset progress bar after 8 seconds
				// add 200ms delay which is the fade in/out time of slides
				setTimeout(() => {
					progressBar.style.width = '0%'
					setActiveIndex((prevIndex) => (prevIndex + 1) % categories.length)
				}, 200)
				sliderRef.current.slickGoTo((activeIndex + 1) % categories.length)
			}
		}

		animationFrameId = requestAnimationFrame(animate)

		return () => cancelAnimationFrame(animationFrameId)
	}, [activeIndex, categories])

	const slides = categories.map((category, index) => {
		return (
			<Slide key={index}>
				<SlideInner>
					<ImageWrapper>
						<BackgroundImage backgroundImage={category.image}>
							<SlideTitle>{category.title && category.title}</SlideTitle>
						</BackgroundImage>
					</ImageWrapper>
					<SlideCopy>{category.copy && category.copy}</SlideCopy>
				</SlideInner>
			</Slide>
		)
	})

	const mobileSlides = categories.map((category, index) => {
		return (
			<Slide key={index}>
				<ImageWrapper>
					<BackgroundImage backgroundImage={category.image}>
						<SlideTitle>{category.title && category.title}</SlideTitle>
					</BackgroundImage>
				</ImageWrapper>
				<ProgressTitle>{category.title && category.title}</ProgressTitle>
				<ProgressBarOuter>
					<ProgressBarInner
						ref={(el) => (progressBarRefs.current[index] = el)}
						style={{
							width: index !== activeIndex && '0%',
						}}
					/>
				</ProgressBarOuter>
				<SlideCopy>{category.copy && category.copy}</SlideCopy>
			</Slide>
		)
	})

	return (
		<SectionWrapper ref={containerRef}>
			<ContentContainer>
				<SectionHeader>
					<Heading>{heading && heading}</Heading>
				</SectionHeader>
				{!isMobileView && (
					<ProgressWrapper>
						{categories.map((category, index) => {
							return (
								<ProgressItem
									key={index}
									onClick={() => onClickSliderNav(index)}
								>
									<ProgressTitle>{category.title}</ProgressTitle>
									<ProgressBarOuter>
										<ProgressBarInner
											ref={(el) => (progressBarRefs.current[index] = el)}
											style={{
												width: index !== activeIndex && '0%',
											}}
										/>
									</ProgressBarOuter>
								</ProgressItem>
							)
						})}
					</ProgressWrapper>
				)}
				<Slider {...slickSettings} ref={sliderRef}>
					{isMobileView ? mobileSlides : slides}
				</Slider>
			</ContentContainer>
		</SectionWrapper>
	)
}

export default SlyleticaCategories
