import React from 'react'

import SEO from '../../components/SEO'

import { HeroImage } from '../../components/homeHeroImage'
import HomeIntro from '../../components/homeIntro'
import HomeSectionsIntro from '../../components/homeSectionsIntro'
import HomeSectionsColumns from '../../components/homeSectionsColumns'
import BrandCollaborators from '../../components/brandCollaborators'
import { BrandShowcase } from '../../components/brandShowcase'
import SlyleticaCategories from '../../components/slyleticaCategories'
import ContactFormNew from '../../components/contactFormNew'

const HomePageNew = ({ pageContext }) => {
	const {
		page: { title, newHomePageFields },
	} = pageContext

	const {
		homeHeaderHeroImage,
		homeHeaderHeroImageMobile,
		homeHeaderCopy,
		homeHeaderCta,
		homeIntroCopy,
		homeIntroBackgroundColour,
		sectionsIntroExtraHeadingWidth,
		sectionsIntroContentPosition,
		sectionsIntroBackgroundImage,
		sectionsIntroHeading,
		sectionsIntroCopy,
		sectionsIntroLink,
		sectionsIntroBackgroundColour,
		sectionsColumnsColumnRepeater,
		brandsHeading,
		brandsFilters,
		brandsBrands,
		brandsShowcaseBackgroundColour,
		brandsShowcaseHeading,
		brandsShowcaseFilters,
		brandsShowcaseBrands,
		slyleticaForHeading,
		slyleticaForCategoriesRepeater,
		responsibilitySectionContentPosition,
		responsibilitySectionBackgroundImage,
		responsibilitySectionHeading,
		responsibilitySectionCopy,
		responsibilitySectionLink,
		contactSectionBackgroundColour,
		contactSectionHeading,
		contactSectionCopy,
	} = newHomePageFields

	return (
		<>
			<SEO title={title} />
			<HeroImage
				image={homeHeaderHeroImage}
				mobileImage={homeHeaderHeroImageMobile}
				copy={homeHeaderCopy}
				cta={homeHeaderCta}
			/>
			<HomeIntro
				copy={homeIntroCopy}
				backgroundColour={homeIntroBackgroundColour}
			/>
			<HomeSectionsIntro
				heading={sectionsIntroHeading}
				copy={sectionsIntroCopy}
				link={sectionsIntroLink}
				backgroundImage={sectionsIntroBackgroundImage}
				contentPosition={sectionsIntroContentPosition}
				extraHeadingWidth={true}
			/>
			<HomeSectionsColumns columnData={sectionsColumnsColumnRepeater} />
			<BrandCollaborators
				heading={brandsHeading}
				filterTags={brandsFilters}
				brands={brandsBrands}
			/>
			<BrandShowcase
				backgroundColour={brandsShowcaseBackgroundColour}
				heading={brandsShowcaseHeading}
				filters={brandsShowcaseFilters}
				brands={brandsShowcaseBrands}
			/>
			<SlyleticaCategories
				heading={slyleticaForHeading}
				categories={slyleticaForCategoriesRepeater}
			/>
			<HomeSectionsIntro
				heading={responsibilitySectionHeading}
				copy={responsibilitySectionCopy}
				link={responsibilitySectionLink}
				backgroundImage={responsibilitySectionBackgroundImage}
				contentPosition={responsibilitySectionContentPosition}
				extraHeadingWidth={false}
			/>
			<ContactFormNew
				heading={contactSectionHeading}
				copy={contactSectionCopy}
				backgroundColour={contactSectionBackgroundColour}
			/>
		</>
	)
}

export default HomePageNew
