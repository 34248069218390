import React from 'react'
import styled from 'styled-components'
import { breakpoint, getColor } from '../../shared/theme'
import { SectionsButtonLink } from '../../elements/sectionsButtonLink'
import Copy from '../../elements/typography/sectionCopy'
import { getImage } from '../../utils/getData'

const SectionsIntroSection = styled.section`
	background-color: #000;
`

const ColumnsWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: 0 auto;

	@media ${breakpoint('tablet')} {
		flex-direction: row;
	}
`

const ColumnWrapper = styled.div`
	width: 100%;
	position: relative;
	color: ${getColor('white')};

	@media ${breakpoint('tablet')} {
		width: 50%;
		min-height: 481px;

		&:hover {
			div {
				h2 {
					transition: opacity 0.3s;
					opacity: 0;
				}
			}
		}
	}
`

const Column = styled.div`
	background-position: center;

	background-size: cover;
	background-repeat: no-repeat;
	min-height: 454px;

	display: flex;
	justify-content: center;
	align-items: center;

	@media ${breakpoint('tablet')} {
		min-height: 481px;
	}
`

const ColumnHeading = styled.h2`
	font-size: 27.5px;
	line-height: 33px;
	font-weight: 800;
	margin-bottom: 28px;
	display: none;

	@media ${breakpoint('tablet')} {
		font-size: 36px;
		line-height: 44px;
		margin-bottom: 0px;
		text-align: center;
		display: block;
	}

	@media ${breakpoint('laptop')} {
		font-size: 50px;
		line-height: 60px;
		width: 65%;
	}
`

const ColumnOverlay = styled.div`
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media ${breakpoint('tablet')} {
		opacity: 0;
		transition: opacity 0.3s;

		&:hover {
			opacity: 1;
		}
	}
`

const OverlayInner = styled.div`
	margin: 54px 8% 47px;
	width: 57%;

	@media ${breakpoint('mobile')} {
		margin: 54px 8.5%;
		width: 65%;
	}

	@media ${breakpoint('laptop')} {
		margin: 110px 104px 106px 88px;
		width: 73.3%;
	}

	@media ${breakpoint('laptop')} {
		width: 65%;
	}
`

const ColumnOverlayHeading = styled.h2`
	font-size: 27.5px;
	line-height: 33px;
	font-weight: 800;
	margin-bottom: 28px;
	text-align: center;
	width: 120%;

	@media ${breakpoint('tablet')} {
		font-size: 36px;
		line-height: 44px;
		margin-bottom: 0px;
		display: none;
		width: 100%;
	}

	@media ${breakpoint('laptop')} {
		font-size: 50px;
		line-height: 60px;
		width: 65%;
	}
`

const ButtonWrapper = styled.div`
	width: 80%;

	@media ${breakpoint('mobile')} {
		width: 40%;
	}

	@media ${breakpoint('tablet')} {
		width: 60%;
	}

	@media ${breakpoint('laptop')} {
		width: 35%;
	}
`

export const HomeSectionsColumns = ({ columnData }) => {
	return (
		<SectionsIntroSection style={{ backgroundColor: '#000' }}>
			<ColumnsWrapper>
				{columnData.map((column, index) => {
					return (
						<ColumnWrapper key={index}>
							<Column
								style={{
									backgroundImage: `url(${getImage(column.backgroundImage)})`,
								}}
								index={index}
							>
								<ColumnHeading>{column.heading}</ColumnHeading>
							</Column>
							<ColumnOverlay>
								<OverlayInner>
									<ColumnOverlayHeading style={{ textAlign: 'left' }}>
										{column.heading}
									</ColumnOverlayHeading>
									<Copy styles={{ marginBottom: '57px', width: '100%' }}>
										{column.copy}
									</Copy>
									<ButtonWrapper>
										<SectionsButtonLink link={column.link} />
									</ButtonWrapper>
								</OverlayInner>
							</ColumnOverlay>
						</ColumnWrapper>
					)
				})}
			</ColumnsWrapper>
		</SectionsIntroSection>
	)
}

export default HomeSectionsColumns
