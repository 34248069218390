import React from 'react'
import styled from 'styled-components'
import { getColor, breakpoint } from '../../shared/theme'

const Button = styled.button`
	background-color: transparent;
	border: 1px solid ${getColor('white')};
	color: ${getColor('white')};
	font-weight: 700;
	text-transform: uppercase;
	text-align: left;
	position: relative;
	grid-column: -1 / 1;
	font-size: 8px;
	padding: 15px 10px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: all 0.2s;

	&:hover {
		border: 1px solid ${getColor('black')};
	}

	@media ${breakpoint('mobile')} {
		max-width: 275px;
	}

	@media ${breakpoint('laptop')} {
		padding: 20px 15px;
		font-size: 12px;
	}

	@media ${breakpoint('desktop')} {
		padding: 23px 17px;
		font-size: 12px;
	}

	span {
		font-weight: 700;
	}

	svg {
		width: 11px;
		height: 11px;

		polyline,
		line {
			stroke-width: 2;
			stroke: ${getColor('white')};
			transition: stroke ${(props) => props.theme.transitionSpeed.default};
		}

		@media ${breakpoint('tablet')} {
			width: 16px;
			height: 16px;
		}
	}

	&:hover {
		background-color: ${(props) => (props.color ? 'white' : 'transparent')};
		color: ${getColor('black')};

		svg {
			polyline,
			line {
				stroke: ${getColor('black')};
			}
		}

		transition: all ${(props) => props.theme.transitionSpeed.default};
	}
`

const BrandButton = ({ newSubmit = false, color = null, copy }) => {
	return (
		<Button type="submit" newSubmit={newSubmit} color={color}>
			<span>{copy && copy}</span>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.4 18.5">
				<polyline
					fill="none"
					points="7.6,17.1 15,9.3 
					7.6,1.4 "
				/>
				<line fill="none" x1="15" y1="9.3" x2="0" y2="9.3" />
			</svg>
		</Button>
	)
}

export default BrandButton
