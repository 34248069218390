import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { breakpoint } from '../../shared/theme'
import Heading from '../../elements/typography/sectionHeading'
import ContentContainer from '../contentContainer'
import { getImage } from '../../utils/getData'
import BrandFilters from '../brandFilters'
import useBrandsFilters from '../../hooks/useBrandFilters'

const SectionWrapper = styled.section`
	padding: 54px 0 70px;

	@media ${breakpoint('tablet')} {
		padding: 69px 0 82px;
	}

	@media ${breakpoint('laptop')} {
		padding: 84px 0 94px;
	}
`

const SectionHeader = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-bottom: 50px;

	@media ${breakpoint('tablet')} {
		flex-direction: row;
		margin-bottom: 100px;
	}
`

const HeadingWrapper = styled.div`
	width: 75%;

	@media ${breakpoint('tablet')} {
		width: 40%;
		margin-bottom: 0px;
	}
`

const SectionBody = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	max-height: 830px;

	@media ${breakpoint('tablet')} {
		max-height: 500px;
	}
`

const ImageWrapper = styled.div`
	width: 50%;
	aspect-ratio: 2;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 20px;

	@media ${breakpoint('tablet')} {
		width: 20%;
	}
`

const BrandImage = styled.img`
	height: auto;
	width: auto;
	max-width: 100%;
	max-height: 60px;

	@media ${breakpoint('tablet')} {
		max-height: 80px;
	}
`

export const BrandCollaborators = ({ heading, filterTags, brands }) => {
	const {
		activeFilter,
		brandsContainerHeight,
		brandsContainerRef,
		handleFilterClick,
		brandFilterIsActive,
	} = useBrandsFilters()

	const filteredBrands = useMemo(() => {
		return brands.filter((brand, index) => {
			return activeFilter === 'all' || brand.filterTags.includes(activeFilter)
		})
	}, [activeFilter])

	return (
		<SectionWrapper>
			<ContentContainer>
				<SectionHeader>
					<HeadingWrapper>
						<Heading>{heading && heading}</Heading>
					</HeadingWrapper>
					<BrandFilters
						handleFilterClick={handleFilterClick}
						activeFilter={activeFilter}
						filterTags={filterTags}
					/>
				</SectionHeader>

				<SectionBody
					ref={brandsContainerRef}
					style={{ height: brandsContainerHeight || 'auto' }}
				>
					{filteredBrands &&
						filteredBrands.map((brand, index) => {
							//only display the first 20
							if (index < 20) {
								return (
									<ImageWrapper key={index}>
										<BrandImage
											src={getImage(brand.image)}
											alt={brand?.image?.altText}
										/>
									</ImageWrapper>
								)
							}
						})}
				</SectionBody>
			</ContentContainer>
		</SectionWrapper>
	)
}

export default BrandCollaborators
