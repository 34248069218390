import React, { useRef, useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { breakpoint, getColor } from '../../shared/theme'
import Heading from '../../elements/typography/sectionHeading'
import ContentContainer from '../contentContainer'
import BrandFilters from '../brandFilters'
import BackgroundImage from '../backgroundImage'
import getStyle from '../../utils/getStyle'
import useBrandsFilters from '../../hooks/useBrandFilters'
import BrandButton from '../brandButton'

const SectionWrapper = styled.section`
	padding: 54px 0 58px;

	@media ${breakpoint('tablet')} {
		padding: 74px 0 92px;
	}

	@media ${breakpoint('laptop')} {
		padding: 97px 0 133px;
	}
`
const SectionHeader = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-bottom: 17px;

	@media ${breakpoint('tablet')} {
		flex-direction: row;
		margin-bottom: 90px;
	}
`

const HeadingWrapper = styled.div`
	margin-bottom: 25px;

	@media ${breakpoint('tablet')} {
		margin-bottom: 0px;
	}
`

const SectionBody = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 1.5%;
	height: fit-content;

	@media ${breakpoint('tablet')} {
		gap: 1% 10px;
	}
`

const ImageBase = styled.div`
	position: relative;
	width: 48.5%;
	aspect-ratio: 1;
	margin-bottom: 5px;
	display: ${(props) => (props.active ? 'block' : 'none')};

	@media ${breakpoint('tablet')} {
		width: 32.5%;
		margin-bottom: 10px;
	}

	@media ${breakpoint('laptop')} {
		width: 32.5%;
	}
`

const ImageWrapper = styled.div`
	width: 100%;
	aspect-ratio: 1;
	display: flex a {
		display: none;
	}

	@media ${breakpoint('tablet')} {
		position: absolute;
		top: 0;
		left: 0;
		z-index: ${({ isEnlarged }) => (isEnlarged ? 10 : 1)};
		transform: ${({ isEnlarged, scaleValue }) =>
			isEnlarged ? `scale(${scaleValue})` : `scale(1)`};
		transition: transform 1s ease, z-index 1.1s ease;
		cursor: pointer;

		a {
			opacity: ${({ isEnlarged }) => (isEnlarged ? 1 : 0)};
			transition: opacity 1s;
		}
	}
`

const BrandCopyWrapper = styled.div`
	position: relative;
	width: 100%;
	aspect-ratio: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`

const BrandFilter = styled.span`
	text-transform: uppercase;
	font-size: 6px;
	line-height: 15px;
	font-weight: 300;
	color: ${getColor('white')};
	text-align: center;

	@media ${breakpoint('tablet')} {
		font-size: 12px;
		line-height: 37px;
	}
`

const BrandName = styled.h4`
	text-transform: uppercase;
	margin: 0;
	font-size: 15px;
	font-weight: 700;
	color: ${getColor('white')};
	text-align: center;
	max-width: 90%;

	@media ${breakpoint('tablet')} {
		font-size: 20px;
	}

	@media ${breakpoint('laptop')} {
		font-size: 37px;
	}
`

const ButtonWrapper = styled.div`
	display: none;
	width: 100%;
	margin-top: 20px;

	@media ${breakpoint('tablet')} {
		display: block;
		width: 70%;
	}
`

const BrandCopyOverlay = styled.div`
	position: absolute;
	top: 0;
	width: 100%;
	aspect-ratio: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 10px;
	opacity: ${({ isEnlarged }) => (isEnlarged ? 1 : 0)};
	z-index: 5;
	background-color: ${getColor('orange')};
	transition: opacity 0.5s;
	color: ${getColor('white')};

	&:hover {
		opacity: 1;
	}

	@media ${breakpoint('mobile')} {
		padding: 20px;
	}

	@media ${breakpoint('laptop')} {
		padding: 30px;
	}
`

const OverlayCopy = styled.p`
	font-size: 9px;
	font-weight: 300;
	margin-bottom: 5px;
	line-height: 1.2;

	@media ${breakpoint('mobile')} {
		font-size: 14px;
		margin-bottom: 10px;
	}

	@media ${breakpoint('tablet')} {
		font-size: 12px;
	}

	@media ${breakpoint('laptop')} {
		font-size: 16px;
	}
`

const BrandBoldText = styled.span`
	font-weight: 700;
`

export const BrandShowcase = ({
	backgroundColour,
	heading,
	filters,
	brands,
}) => {
	const {
		activeFilter,
		brandsContainerHeight,
		brandsContainerRef,
		handleFilterClick,
		brandFilterIsActive,
	} = useBrandsFilters()

	const textColour = backgroundColour === '#000000' ? 'white' : 'black'

	const [enlargedIndex, setEnlargedIndex] = useState(null)
	const imageRef = useRef()
	const [maxVisibleBrands, setMaxVisibleBrands] = useState(8)

	const handleImageClick = (index) => {
		if (index === enlargedIndex) {
			setEnlargedIndex(null)
		} else {
			setEnlargedIndex(index)
		}
	}

	useEffect(() => {
		if (window.innerWidth < 889) {
			setMaxVisibleBrands(8)
		} else {
			setMaxVisibleBrands(9)
		}
	}, [])

	const filteredBrands = useMemo(() => {
		return brands.filter((brand, index) => {
			return (
				(activeFilter === 'all' && brand.featuredBrand) ||
				brand.filterTags.includes(activeFilter)
			)
		})
	}, [activeFilter])

	// handle click should only run on mobile because we have the hover
	// state on desktop
	useEffect(() => {
		const handleClick = (index) => {
			return () => {
				handleImageClick(index)
			}
		}

		if (window.innerWidth < 889) {
			brands.forEach((brand, index) => {
				const imageWrapper = document.getElementById(`image-wrapper-${index}`)
				const brandCopyOverlay = document.getElementById(
					`brand-copy-overlay-${index}`,
				)

				if (imageWrapper && brandCopyOverlay) {
					imageWrapper.addEventListener('click', handleClick(index))
					brandCopyOverlay.addEventListener('click', handleClick(index))
				}
			})
		}

		return () => {
			// Cleanup event listeners if needed
		}
	}, [brands, enlargedIndex, handleImageClick])

	return (
		<SectionWrapper style={{ backgroundColor: getStyle(backgroundColour) }}>
			<ContentContainer>
				<SectionHeader>
					<HeadingWrapper>
						<Heading styles={{ color: textColour }}>{heading}</Heading>
					</HeadingWrapper>
					<BrandFilters
						handleFilterClick={handleFilterClick}
						activeFilter={activeFilter}
						filterTags={filters}
						backgroundColour={backgroundColour}
					/>
				</SectionHeader>

				<SectionBody ref={brandsContainerRef}>
					{filteredBrands &&
						filteredBrands.map((brand, index) => {
							const isEnlarged = index === enlargedIndex

							if (index < maxVisibleBrands) {
								return (
									<ImageBase
										key={index}
										active={brandFilterIsActive(brand.filterTags)}
										ref={imageRef}
									>
										<ImageWrapper id={`image-wrapper-${index}`}>
											<BackgroundImage
												backgroundImage={brand.image}
												styles={{
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<BrandCopyWrapper>
													<BrandFilter>
														{brand.filterTags && brand.filterTags[0]}
													</BrandFilter>
													<BrandName>{brand.title && brand.title}</BrandName>
												</BrandCopyWrapper>
											</BackgroundImage>
										</ImageWrapper>
										<BrandCopyOverlay
											isEnlarged={isEnlarged}
											id={`brand-copy-overlay-${index}`}
										>
											<OverlayCopy>
												<BrandBoldText>Task:</BrandBoldText> {brand.task}
											</OverlayCopy>
											<OverlayCopy>
												<BrandBoldText>Effort:</BrandBoldText> {brand.effort}
											</OverlayCopy>
											<OverlayCopy>
												<BrandBoldText>Outcome:</BrandBoldText> {brand.outcome}
											</OverlayCopy>
											{brand.link && (
												<ButtonWrapper>
													<a
														href={`${
															brand.link.url
														}?${brand.title
															.toLowerCase()
															.split(' ')
															.join('-')}`}
														target={brand.link.target}
													>
														<BrandButton copy={brand.link.title} />
													</a>
												</ButtonWrapper>
											)}
										</BrandCopyOverlay>
									</ImageBase>
								)
							}
						})}
				</SectionBody>
			</ContentContainer>
		</SectionWrapper>
	)
}
